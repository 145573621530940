import {apiUrl, headers} from '@/config/config';
import axios from "axios";
import Storage from '@/store/modules/Storage';
const headersApi = { 
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data',
    'Accept': '*/*'
}

class Alerts{

    async alertCount(status){
        return await axios.get(`${apiUrl}alert/count`,{
            
        })
        .then(response =>{
            return response;
        });
    }

    async deleteAlter(id){
        return await axios.delete(`${apiUrl}alert/delete`,  {params: {id: id}})
        .then(response => {
            return response;
        });  
    }


}

export default  new Alerts();