import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_id: 0,
    overlay: true
  },
  getters: {
    userId: function(state){
      return state.user_id
    },
    overlay: function(state){
      return state.overlay
    }
  },
  mutations: {
    SET_USER_ID(state, payload){
      state.user_id = payload
    },
    SET_OVERLAY(state, payload){
      state.overlay = payload
    }
  },
  actions: {
    setUserId(context, payload){
      context.commit('SET_USER_ID', payload);
    },
    setOverlay(context, payload){
      context.commit('SET_OVERLAY', payload);
    }
  },
  modules: {
  }
})
