import {apiUrl, headers} from '@/config/config';
import axios from "axios";
import Storage from '@/store/modules/Storage';
const headersApi = { 
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data',
    'Accept': '*/*'
}

class Auth{

    async login(username, password){
        return await axios.post(`${apiUrl}login`, {
            email: username,
            password: password,
            origin: 'web'
        },{
            headers: headersApi,
        })
        .then(response => {
            const token = response.data.token;
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            return response;
        })
        .catch(err => {
            return err.response.data
            
        });
    }

    async remember(username){
        return await axios.post(`${apiUrl}remember`)
        .then(response =>{
            return response;
        });
    }

    async get(){
        return await axios.get('http://localhost:8000/');
    }

    async recoveryPassword(email){
        return await axios.post(`${apiUrl}recoverypassword`, {
                email: email
        })
        .then(response =>{
            return response;
        });
    }
    removeAuth(){
        axios.defaults.headers.common["Authorization"] = `Bearer `
    }
    async changePassword(form){
        return await axios.post(`${apiUrl}changePassword`, form)
        .then(response =>{
            return response;
        });
    }

}

export default  new Auth();