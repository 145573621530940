<template>
  <DashboardLayout>
    <hello-world />
  </DashboardLayout>
</template>

<script>
  import HelloWorld from '../components/HelloWorld';
  import DashboardLayout from '@/layout/DashboardLayout';

  export default {
    name: 'Home',

    components: {
      HelloWorld,
      DashboardLayout
    },
  }
</script>
