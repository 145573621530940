import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2';
import '@/assets/styles/global.css';


const optionsSweet = {
  confirmButtonColor: '#0D3531',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2, optionsSweet);
Vue.use(VueClipboard);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
