<template>
    <v-overlay :absolute="absolute" :value="$store.state.overlay" color="#0d3531">
        <v-row align="center" align-content="center" style="text-align: center;">
            <v-col cols="6" align-self="center">
                <v-img alt="Planti" class="shrink mr-2" contain lazy-src="/img/logo_planti_08.png"
                    src="/img/logo_planti_08.png" transition="scale-transition" width="200" />
            </v-col>
            <v-col cols="6" align-self="center" style="text-align: center;">
                <v-progress-circular indeterminate color="green" :size="50"></v-progress-circular>
            </v-col>
        </v-row>
    </v-overlay>
</template>

<script>
export default {
    props: {
        overlay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            absolute: true
        }
    },

}
</script>

<style>

</style>