import Storage from './Storage';
import jwt_decode from "jwt-decode";

class IsAuth{

    auth(){
        let dataJWT = Storage.getToken();
        const user = Storage.getUser();
      
        if(dataJWT != false && user != false){
            const  payload  = jwt_decode(dataJWT);
            if(user.email == payload.email){
                return true;
            }
            Storage.removeData();
            return false;
        }else{
            return false;
        }
       
    }
}
export default new IsAuth();