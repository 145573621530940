<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    layout: `div`,
  }),
};
</script>
