import axios from "axios";
import Storage from "@/store/modules/Storage";
axios.defaults.headers.common["Authorization"] = `Bearer ${Storage.getToken()}`;
import {apiUrl} from "@/config/config";

class Setting{

    async get(){
        return await axios.get(`${apiUrl}getApiKey`)
        .then(response => {
            return response.data;
        });
    }
}

export default new Setting();